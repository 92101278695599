import { defineStore } from 'pinia'
import axios from "@axios"
import LZString from 'lz-string'
import pluralize from 'pluralize'
import moment from "moment/moment"

export const useAppDataStore = defineStore('appData', {
  state: () => ({
    userTypes: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.userTypes ? appData.userTypes : []
    })(),
    userTypesVersion: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.userTypesVersion ? appData.userTypesVersion : 0
    })(),
    users: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.users ? appData.users : []
    })(),
    usersVersion: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.usersVersion ? appData.usersVersion : 0
    })(),
    tagTypes: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.tagTypes ? appData.tagTypes : []
    })(),
    tagTypesVersion: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.tagTypesVersion ? appData.tagTypesVersion : 0
    })(),
    tags: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.tags ? appData.tags : []
    })(),
    tagsVersion: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.tagsVersion ? appData.tagsVersion : 0
    })(),
    vehicleModels: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.vehicleModels ? appData.vehicleModels : []
    })(),
    vehicleModelsVersion: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.vehicleModelsVersion ? appData.vehicleModelsVersion : 0
    })(),
    vehicleMakes: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.vehicleMakes ? appData.vehicleMakes : []
    })(),
    vehicleMakesVersion: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.vehicleMakesVersion ? appData.vehicleMakesVersion : 0
    })(),
    vehicleBodyTypes: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.vehicleBodyTypes ? appData.vehicleBodyTypes : []
    })(),
    vehicleBodyTypesVersion: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.vehicleBodyTypesVersion ? appData.vehicleBodyTypesVersion : 0
    })(),
    leadTypes: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.leadTypes ? appData.leadTypes : []
    })(),
    leadTypesVersion: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.leadTypesVersion ? appData.leadTypesVersion : 0
    })(),
    leadMilestones: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.leadMilestones ? appData.leadMilestones : []
    })(),
    leadMilestonesVersion: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.leadMilestonesVersion ? appData.leadMilestonesVersion : 0
    })(),
    leadSources: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.leadSources ? appData.leadSources : []
    })(),
    leadSourcesVersion: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.leadSourcesVersion ? appData.leadSourcesVersion : 0
    })(),
    leadLostReasons: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.leadLostReasons ? appData.leadLostReasons : []
    })(),
    leadLostReasonsVersion: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.leadLostReasonsVersion ? appData.leadLostReasonsVersion : 0
    })(),
    teamTaskTypes: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.teamTaskTypes ? appData.teamTaskTypes : []
    })(),
    teamTaskTypesVersion: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.teamTaskTypesVersion ? appData.teamTaskTypesVersion : 0
    })(),
    contacts: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.contacts ? appData.contacts : []
    })(),
    contactsVersion: (() => {
      const appData = JSON.parse(localStorage.getItem('appData'))

      return appData && appData.contactsVersion ? appData.contactsVersion : 0
    })(),
    teamTaskPriorities: [
      { id: 1, title: 'Normal' },
      { id: 2, title: 'High' },
    ],
    teamTaskStatuses: [
      { id: 1, title: 'To Do' },
      { id: 3, title: 'Completed' },
    ],
    contactTitles: [
      { id: 1, title: 'Mr' },
      { id: 2, title: 'Mrs' },
      { id: 3, title: 'Ms' },
      { id: 4, title: 'Miss' },
      { id: 5, title: 'Dr' },
    ],
    ppc: [
      {id: 0, title: 'No'},
      {id: 1, title: 'Yes'},
    ],
    rangeOptions: [
      { id: 1, title: 'Today', dates: { from: moment().startOf('day'), to: moment().endOf('day') } },
      { id: 2, title: 'Yesterday', dates: { from: moment().startOf('day').subtract(1, 'day'), to: moment().endOf('day').subtract(1, 'day') } },
      { id: 3, title: 'This Week', dates: { from: moment().startOf('isoWeek').startOf('day'), to: moment().endOf('day') } },
      { id: 4, title: 'Last Week', dates: { from: moment().subtract(1, 'week').startOf('isoWeek'), to: moment().subtract(1, 'week').endOf('isoWeek') } },
      { id: 5, title: 'Last 7 days', dates: { from: moment().startOf('day').subtract(7, 'days'), to: moment().endOf('day').subtract(1, 'day') } },
      { id: 6, title: 'This Month', dates: { from: moment().startOf('month'), to: moment().endOf('day') } },
      { id: 7, title: 'Last Month', dates: { from: moment().subtract(1, 'month').startOf('month'), to: moment().subtract(1, 'month').endOf('month') } },
      { id: 8, title: 'Last 30 Days', dates: { from: moment().startOf('day').subtract(30, 'days'), to: moment().endOf('day') } },
      { id: 9, title: 'Last 3 Months', dates: { from: moment().subtract(3, 'months').startOf('month'), to: moment().subtract(1, 'month').endOf('month') } },
      { id: 10, title: 'Last 6 Months', dates: { from: moment().subtract(6, 'months').startOf('month'), to: moment().subtract(1, 'month').endOf('month') } },
      { id: 11, title: 'Last 12 Months', dates: { from: moment().subtract(12, 'months').startOf('month'), to: moment().subtract(1, 'month').endOf('month') } },
      { id: 0, title: 'Custom Range' },
    ],
    weekRangeOptions: [
      { id: 1, title: 'Last 4 Weeks', dates: { from: moment().startOf('isoWeek').subtract(4, 'week'), to: moment().endOf('isoWeek').subtract(1, 'week') } },
      { id: 2, title: 'Last 8 Weeks', dates: { from: moment().startOf('isoWeek').subtract(8, 'week'), to: moment().endOf('isoWeek').subtract(1, 'week') } },
      { id: 3, title: 'Last 16 Weeks', dates: { from: moment().startOf('isoWeek').subtract(16, 'week'), to: moment().endOf('isoWeek').subtract(1, 'week') } },
      { id: 4, title: 'Last 32 Weeks', dates: { from: moment().startOf('isoWeek').subtract(32, 'week'), to: moment().endOf('isoWeek').subtract(1, 'week') } },
      { id: 0, title: 'Custom Range' },
    ],
    monthRangeOptions: [
      { id: 1, title: 'Last 3 Months', dates: { from: moment().startOf('month').subtract(3, 'month'), to: moment().endOf('month').subtract(1, 'month') } },
      { id: 2, title: 'Last 6 Months', dates: { from: moment().startOf('month').subtract(6, 'month'), to: moment().endOf('month').subtract(1, 'month') } },
      { id: 3, title: 'Last 9 Months', dates: { from: moment().startOf('month').subtract(9, 'month'), to: moment().endOf('month').subtract(1, 'month') } },
      { id: 4, title: 'Last 12 Months', dates: { from: moment().startOf('month').subtract(12, 'month'), to: moment().endOf('month').subtract(1, 'month') } },
    ],
  }),
  getters: {
    getUserTypes: state => state.userTypes,
    userTypeTitle: state =>  userTypeId => {
      const userType = state.userTypes.find(type => type.id === userTypeId)

      return userType ? userType.title : ''
    },
    userFullName: state => userId => {
      const user = state.users.find(user => user.id === userId)

      return user ? user.firstName + ' ' + user.secondName : ''
    },
    userAvatar: state => userId => {
      const user = state.users.find(user => user.id === userId)

      return user && user.avatar !== null ? `/storage/img/uploads/avatars/${userId}/${user.avatar}`: null
    },
    getTagTypes: state => state.tagTypes,
    getContactsOwners: state => {
      const users = JSON.parse(JSON.stringify(state.users))
      const contactsOwners = []

      users.filter(item => item.userType === 4 || item.userType === 1).forEach(item =>{
        contactsOwners.push({ title: `${item.firstName} ${item.secondName}`, id: item.id, avatar: item.avatar })
      })

      return contactsOwners

    },
    getAllUsers: state => {
      const users = JSON.parse(JSON.stringify(state.users))
      const allUsers = []

      users.forEach(item =>{
        allUsers.push({ title: `${item.firstName} ${item.secondName}`, id: item.id, avatar: item.avatar })
      })

      return allUsers
    },
    getLeadsTags: state => {
      const tags = JSON.parse(JSON.stringify(state.tags))

      return tags.filter(item => item.types.includes(2))
    },
    getContactTags: state => {
      const tags = JSON.parse(JSON.stringify(state.tags))

      return tags.filter(item => item.types.includes(1))
    },
    getVansMakeModels: state => {
      const makes = state.vehicleMakes
      const models = state.vehicleModels
      const makeModels = []

      models.forEach(item => {
        const makeId = item.vehicle_make_id
        const make = makes.find(item => item.id === makeId)
        const makeTitle = make && make.title ? make.title : ''
        const modelTitle = item.title ? item.title : ''

        makeModels.push({ id: item.id, title: `${makeTitle} ${modelTitle} ` })
      })

      return makeModels
    },
    getVansMakeModelsBodyTypes: state => {
      const makes = state.vehicleMakes
      const models = state.vehicleModels
      const bodyTypes = state.vehicleBodyTypes
      const makeModelsBodyTypes = []

      bodyTypes.forEach(item => {
        const modelId = item.vehicle_model_id
        const model = models.find(item => item.id === modelId)
        const makeId = model.vehicle_make_id
        const make = makes.find(item => item.id === makeId)

        // Titles
        const modelTitle = model && model.title ? model.title : ''
        const makeTitle = make && make.title ? make.title : ''
        const bodyTitle = item.title ? item.title : ''

        makeModelsBodyTypes.push({ id: item.id, title: `${makeTitle} ${modelTitle} ${bodyTitle}` })
      })

      return makeModelsBodyTypes
    },
    getVansMakeModelsBodyTypesWithUnknown: state => {

      const makes = state.vehicleMakes
      const models = state.vehicleModels
      const bodyTypes = state.vehicleBodyTypes
      const makeModelsBodyTypes = []

      bodyTypes.forEach(item => {
        const modelId = item.vehicle_model_id
        const model = models.find(item => item.id === modelId)
        const makeId = model.vehicle_make_id
        const make = makes.find(item => item.id === makeId)

        // Titles
        const modelTitle = model && model.title ? model.title : ''
        const makeTitle = make && make.title ? make.title : ''
        const bodyTitle = item.title ? item.title : ''

        makeModelsBodyTypes.push({ id: item.id, title: `${makeTitle} ${modelTitle} ${bodyTitle}` })
      })


      makeModelsBodyTypes.unshift({ id: 0, title: 'Unknown' })

      return makeModelsBodyTypes
    },
    getLeadSourcesWithUnknown: state => {
      const leadSources = JSON.parse(JSON.stringify(state.leadSources))

      leadSources.unshift({ id: 0, title: 'Unknown' })

      return  leadSources
    },
  },
  actions: {
    async check(data){
      try {
        // get backend version
        const res = await axios.get(`/api/app-data/${data}/version`)
        if (res.status === 201){
          const backEndVersion = res.data

          // compare backend and frontend version
          if(backEndVersion !== this[`${data}Version`]){
            this.fetch(data)
          }
        }

      }
      catch (err){
        console.log(err)
      }

    },
    async fetch(data){
      try {
        // get backend version
        const res = await axios.get(`/api/app-data/${data}/get`)
        if (res.status === 201){
          this[data] = JSON.parse(LZString.decompressFromBase64(res.data.data))
          this[`${data}Version`] = res.data.version
          localStorage.setItem('appData', JSON.stringify(this.$state))
        }

        return false
      }
      catch (err){
        console.log(err)
      }
    },
    set(model, data, version) {
      model = pluralize(model)
      this[model] = data
      this[`${model}Version`] = version
    },
    search(q){
      return new Promise((resolve, reject) => {
        axios.get(`/api/search`, {
          params: {
            q,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
})

