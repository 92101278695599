import axios from 'axios'
import { useUserStore } from "@/stores/user"
import { useToast } from "vue-toastification"

const toast = useToast()
const token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : null
const baseURL = process.env.VUE_APP_SERVER_URL
const axiosIns = axios.create({
// You can add your headers here
// ================================
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
  },
})

axiosIns.interceptors.response.use(

  async res => {
    return res
  },

  async error => {
    // log out automatically unauthorized users
    if (error.response.status === 401) {

      const url = error.request.responseURL

      // exclude:
      // 1. search - sometimes when user types very fast laravel return 401 between valid responses
      // 2. Logout - prevent loop
      if (!url.includes('api/search') && !url.includes('api/logout') && !url.includes('api/notifications/')){
        // logout and show message
        if(! await useUserStore().checkUserToken()){
          await useUserStore().logout(false)
          toast.error(error.response.statusText)
        }

      }

    }

    return Promise.reject(error)
  },
)

export default axiosIns

