import { defineStore } from 'pinia'
import axios from "@axios"
import ability, { initialAbility } from "@/plugins/casl/ability"
import router from "@/router"
import { useToast } from "vue-toastification"

const toast = useToast()

export const useUserStore = defineStore('user', {
  state: () => ({
    // returns boolean to determine is the user authenticated
    authenticated: (() => {
      const userData = JSON.parse(localStorage.getItem('user'))

      return userData && userData.authenticated ? userData.authenticated : false
    })(),

    // returns user data
    user: (() => {
      const userData = JSON.parse(localStorage.getItem('user'))

      return userData && userData.user ? userData.user : null
    })(),

    // returns user bearer token for Laravel Sanctum
    token: (() => {
      const userData = JSON.parse(localStorage.getItem('user'))

      return userData && userData.token ? userData.token : null
    })(),

    // returns user oAuth token for ringCentral
    ringCentralToken: (() => {
      const userData = JSON.parse(localStorage.getItem('user'))

      return userData && userData.ringCentralToken ? userData.ringCentralToken : null
    })(),

    //return CASL abilities
    abilities: (() => {
      const userData = JSON.parse(localStorage.getItem('user'))

      return userData && userData.abilities ? userData.abilities : null
    })(),
  }),
  getters: {
    isAuthenticated: state => state.authenticated,
    userData: state => state.user,
    userToken: state => state.token,
    userId: state => state.user ? state.user.id : null,
    isSalesTeam: state => state.user ? state.user.userType === 4 || state.user.userType === 1 : false,
    getAbilities: state => state.abilities,
  },
  actions: {
    async login({ email, password }){
      try {
        // get Laravel Sanctum CSRF Cookie
        await axios.get('sanctum/csrf-cookie')

        // login api route
        const res = await axios.post('/api/login', { email, password }, {
          withCredentials: false,
          headers: {
            Authorization: null,
          },
        })
        if (res.status === 201) {
          // set store data
          this.authenticated = true
          this.user = res.data.user
          this.token = res.data.accessToken
          this.abilities = res.data.abilities

          // save store data in local store
          localStorage.setItem('user', JSON.stringify(this.$state))

          // update CASL abilities
          await ability.update(res.data.abilities)
        }
      }
      catch (e) {
        // backend will return 422 error code when the credentials are incorrect
        if(e.response.status === 422){
          toast.error(e.response.data.message, {
            timeout: 2000,
          })
        } else {
          toast.error('Something went wrong, contact the administrator', {
            timeout: 2000,
          })
          console.log(e)
        }

      }

    },
    async logout(removeTokenFromDB){

      // if logout was initiated by user, not by api response 401 error, delete token from db
      if(removeTokenFromDB) {

        try {

          await axios.post('/api/logout')

        } catch (e) {

          console.log(e)

        }
      }

      // remove user data from local storage
      localStorage.removeItem('user')

      // reset user store
      this.authenticated = false
      this.user = null
      this.token = null
      this.abilities = null

      // Update Abilities
      ability.update(initialAbility)

      // redirect to login page
      await router.push({ path: '/login', query: { to: window.location.pathname } })
    },
    saveRingCentralToken(data){
      this.ringCentralToken = data

      // save store data in local store
      localStorage.setItem('user', JSON.stringify(this.$state))

    },
    async checkUserToken(){
      const res = await axios.post('/api/check-user')

      return res.status === 204
    },
  },
})
