import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import routes from '~pages'
import { canNavigate } from '@layouts/plugins/casl'
import { useAppDataStore } from "@/stores/appData"
import { useNotificationsStore } from "@/stores/notificationsStore"

const router = createRouter({
  history: createWebHistory(),
  routes: [


    // redirect main page to dashboard
    {
      path: '/',
      redirect: to => {
        return { name: 'dashboards-analytics', query: to.query }
      },
    },
    ...setupLayouts(routes),
  ],
})


// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
router.beforeEach(to => {
  // Import user store and set isLoggedIn value
  const user = JSON.parse(localStorage.getItem('user'))
  const isLoggedIn = user && user.authenticated ? user.authenticated : false


  // Check if user can navigate to the route based on his abilities
  if (canNavigate(to)) {
    if (to.meta.redirectIfLoggedIn && isLoggedIn)
      return '/'
  }
  else {
    if (isLoggedIn)

      return { name: 'not-authorized' }

    else

      return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } }

  }

})
router.afterEach((to, from) => {

  // Import user store and set isLoggedIn value
  const user = JSON.parse(localStorage.getItem('user'))
  const isLoggedIn = user && user.authenticated ? user.authenticated : false

  // After login compare localStorage AppData (if there's any) and get new one if necessary
  if(from.name === 'login' || from.path === '/'){

    if(isLoggedIn){

      const appDataStore = useAppDataStore()

      appDataStore.check('userTypes')
      appDataStore.check('users')
      appDataStore.check('tagTypes')
      appDataStore.check('tags')
      appDataStore.check('vehicleMakes')
      appDataStore.check('vehicleModels')
      appDataStore.check('vehicleBodyTypes')
      appDataStore.check('leadTypes')
      appDataStore.check('leadMilestones')
      appDataStore.check('leadSources')
      appDataStore.check('leadLostReasons')
      appDataStore.check('teamTaskTypes')

      useNotificationsStore().getNewNotifications()

    }

  }
})
export default router

export class route {
}
