import { defineStore } from 'pinia'
import axios from "@axios"
import { useUserStore } from "@/stores/user"

export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    notifications: [],
    overDueTasks: 0,
    overDueLeads: 0,
    unreadTexts: 0,
  }),
  getters: {
    count: state => state.notifications.length,
  },
  actions: {
    getNewNotifications(){
      const id  = useUserStore().userId

      return new Promise((resolve, reject) => {
        axios.get(`/api/notifications/user/${id}/new`)
          .then(response => {
            this.notifications = response.data.notifications
            this.overDueLeads = response.data.overDueLeads
            this.overDueTasks = response.data.overDueTasks
            this.unreadTexts = response.data.unreadTexts

            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    markNotificationAsRead(id){
      return new Promise((resolve, reject) => {
        axios.post(`/api/notifications/notification/${id}/mark-as-read`)
          .then(response => {
            this.notifications = this.notifications.filter(item => item.id !== id)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    markAllNotificationAsRead(){
      const id  = useUserStore().userId
      
      return new Promise((resolve, reject) => {
        axios.post(`/api/notifications/user/${id}/mark-all-as-read`)
          .then(response => {
            this.notifications = []
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
})
